<template>
    <v-menu top left offset-y :close-on-content-click="false" >
        <template v-slot:activator="{ on, attrs }">
            <img v-on="on" :bind="attrs" :src="require('@/assets/img/chat/emoji.svg')" class="pointer mt-1" alt="эмодзи"/>
        </template>
        <div class="emoji-picker">
            <Picker :i18n="i18n" :include="include" :emojiSize="20" :sheetSize="16" native :show-preview="false" :showSearch="false" color="#289E80" @select="selectEmoji"/>
        </div>
    </v-menu>
</template>

<script>
    import { Picker } from 'emoji-mart-vue';

    export default {
        name: "EmojiPicker",
        components: {
            Picker,
        },
        props: {
            scriptIndex: {
                type: Number,
                required: false,
            }
        },
        data: () => ({
            include: [
                'recent',
                'people',
                'nature',
                'foods',
                'activity',
                'places'
            ],
            i18n: {
                search: 'Поиск',
                notfound: 'Эмодзи не найдено',
                categories: {
                    search: 'Результаты поиска',
                    recent: 'Часто используемые',
                    people: 'Смайлики и люди',
                    nature: 'Животные и природа',
                    foods: 'Еда и напитки',
                    activity: 'Активность',
                    places: 'Места и путешествия',
                    objects: 'Объекты',
                    symbols: 'Символы',
                    flags: 'Флаги',
                }
            },
        }),
        methods: {
            selectEmoji(e) {
                if (this.scriptIndex || this.scriptIndex === 0) {
                    this.$emit('pushEmoji', this.scriptIndex, e.native)
                } else {
                    this.$emit('pushEmoji', e.native)
                }
            }
        },
    }
</script>

<style scoped>

</style>
